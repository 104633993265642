import * as React from 'react'
import Layout from '@lekoarts/gatsby-theme-minimal-blog/src/components/layout'

const NotFound = () => (
  <Layout>
    <h1>404 - Page not Found</h1>
    <p>Unfortunately we couldn't find what you were looking for.</p>
    <p>
      <a href='/'>« Go back</a> to the home page.
    </p>
  </Layout>
)

export default NotFound
